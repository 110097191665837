<div class="upload-images-component">
  <!-- IMAGE MAIN -->
  <div
    class="relative border border-gray-200 rounded-2xl flex overflow-hidden pb-[92%]"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
  >
    <div class="absolute h-full w-full flex items-center justify-center">
      <div *ngIf="attachments.length === 0; else existsImage" class="flex flex-col gap-2 m-auto">
        <div class="upload-icon w-fit h-fit m-auto">
          <div class="icon-upload-cloud bg-gray-200 rounded-full w-10 h-10 p-[10px] self-center">
            <span class="{{ SoctripIcons.UPLOAD_CLOUD_02 }} text-xl"> </span>
          </div>
        </div>
        <ng-container [ngTemplateOutlet]="uploadSection"></ng-container>
      </div>
    </div>

    <div *ngIf="attachments.length > 0" class="absolute bottom-3 right-3 justify-end flex items-center gap-2">
      <div
        (click)="avatarIndex = attachmentFocus"
        class="h-8 rounded-full bg-gray-500/60 flex px-2 gap-2 items-center cursor-pointer {{
          isViewMode ? 'hidden' : ''
        }}"
      >
        <i
          class="pi pi-star-fill {{ avatarIndex == attachmentFocus ? 'text-yellow-400' : 'text-white' }}"
          style="font-size: 1rem"
        ></i>
        <span class="text-sm text-white">
          {{ managementPrefix + "cover-image" | translate | sentenceCase }}
        </span>
      </div>
      <div
        (click)="onDeleteImage(0)"
        class="bg-gray-500/60 rounded-full w-8 h-8 flex items-center justify-center hover:bg-gray-500 cursor-pointer {{
          isViewMode ? 'hidden' : ''
        }}"
      >
        <span class="{{ SoctripIcons.TRASH_01 }} text-xl before:!text-white"> </span>
      </div>
    </div>
  </div>
  <!-- LIST IMAGE -->
  <div *ngIf="attachmentsDisplay.length" class="image-list overflow-x-auto">
    <ul class="my-4 whitespace-nowrap list-none">
      <li
        *ngFor="let attachment of attachmentsDisplay; let i = index"
        (click)="attachmentFocus = i"
        class="w-[88px] h-[88px] border rounded-2xl overflow-hidden relative mr-6 inline-block cursor-pointer"
      >
        <app-attachment-display class="block h-full" [attachment]="attachment"></app-attachment-display>

        <div class="absolute bottom-2 left-[50%] translate-x-[-50%] flex gap-[6px] {{ isViewMode ? 'hidden' : '' }}">
          <div
            class="w-7 h-7 flex items-center justify-center bg-gray-500/60 cursor-pointer hover:bg-gray-500 rounded-full"
            (click)="onChangeAvatar(i)"
          >
            <i
              class="pi pi-star-fill {{ avatarIndex == i ? 'text-yellow-400' : 'text-white' }}"
              style="font-size: 1rem"
            ></i>
          </div>
          <div
            (click)="onDeleteImage(i)"
            class="w-7 h-7 flex items-center justify-center bg-gray-500/60 cursor-pointer hover:bg-gray-500 rounded-full"
          >
            <span class="{{ SoctripIcons.TRASH_01 }} text-xl before:!text-white"> </span>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <!-- UPLOAD IMAGE -->
  <div
    *ngIf="attachments.length > 0 && attachments.length < 10 && !isViewMode"
    class="bg-gray-100 border rounded-lg h-[74px]"
    (drop)="onDrop($event)"
    (dragover)="onDragOver($event)"
    (dragleave)="onDragLeave($event)"
  >
    <ng-container [ngTemplateOutlet]="uploadSection"></ng-container>
  </div>

  <!-- UPLOAD LINK -->
  <div *ngIf="attachments.length < 10 && !isViewMode" class="mt-5 flex gap-4">
    <input
      [(ngModel)]="link"
      (ngModelChange)="onLinkChange($event)"
      class="!h-[42px] flex-1"
      type="text"
      pInputText
      [placeholder]="managementPrefix + 'upload-link' | translate | sentenceCase"
    />
    <app-button
      (onClick)="onUploadLink()"
      [disabled]="!isValidLink"
      [label]="actionPrefix + 'upload' | translate | sentenceCase"
    ></app-button>
  </div>

  <div class="my-4 text-gray-700 text-sm {{ isViewMode ? 'hidden' : '' }}">
    {{ managementPrefix + "total-upload-image" | translate | sentenceCase }}: {{ attachments.length }}/{{
      maxLengthImages
    }}
  </div>
  <div class="flex items-center gap-2 text-xs bg-gray-100 rounded-md p-2 {{ isViewMode ? 'hidden' : '' }}">
    <i [class]="SoctripIcons.INFO_CIRCLE"></i>
    <span class="text-gray-700 text-xs">
      {{ managementPrefix + "text-description.image-ratio-suggestion" | translate | sentenceCase }}
    </span>
  </div>
</div>

<ng-template #uploadSection>
  <div class="upload-place h-full w-fit m-auto flex items-start gap-2 px-4 text-center">
    <input
      class="hidden"
      accept="image/svg+xml, image/png, image/jpg, image/jpeg, image/bmp, image/webp, image/tiff, image/gif"
      #inputImage
      type="file"
      [multiple]="true"
      (click)="inputImage.value = ''"
      (change)="onSelectImage($event)"
    />
    <div class="flex items-center gap-1 cursor-pointer text-blue-700 font-bold text-sm" (click)="inputImage.click()">
      <span class="w-max">{{ descriptionPrefix + "click-to-upload" | translate | sentenceCase }}</span>
      <span class="text-orange-dark-500">*</span>
    </div>
    <div class="text-sm text-gray-600 text-left">
      {{ descriptionPrefix + "or-drag-and-drop" | translate }}
      SVG+XML, WEBP, TIFF, PNG, JPG, JPEG, BMP {{ "common.or" | translate }} GIF ({{
        managementPrefix + descriptionPrefix + "validate-image" | translate
      }})
    </div>
  </div>
</ng-template>

<ng-template #existsImage>
  <div class="flex-1 w-full h-full">
    <app-attachment-display
      [isControlsVideo]="true"
      [attachment]="attachmentsDisplay[attachmentFocus]"
    ></app-attachment-display>
  </div>
</ng-template>
