<div ngModelGroup="general_info" class="bg-white rounded-lg border px-4 py-6">
  <div *ngIf="productData?.moderate_status === 'BLOCKED' && isViewMode" class="pb-4">
    <p class="text-error-500 font-semibold">Reason: {{ productData?.moderate_note }}</p>
  </div>
  <h3 class="mb-6 font-semibold">
    {{ managementPrefix + "general-information" | translate | sentenceCase }}
  </h3>

  <!-- OTHER INFO -->
  <div *ngIf="isViewMode" class="flex flex-col gap-4 text-sm mb-6">
    <div>
      <label class="font-medium" htmlFor="description"> Shop name: </label>
      <span>{{ productData.shop?.name || "--" }}</span>
    </div>

    <div>
      <label class="font-medium" htmlFor="description"> Shop code: </label>
      <span>{{ productData.s_sku?.split("_")?.[0] || "--" }}</span>
    </div>

    <div>
      <label class="font-medium" htmlFor="description"> Product code: </label>
      <span>{{ productData.s_sku || "--" }}</span>
    </div>
    <div class="flex gap-2">
      <label class="font-medium" htmlFor="description"> Created/edited by: </label>

      <div class="flex flex-wrap gap-3">
        <div class="flex align-items-center">
          <ng-container
            [ngTemplateOutlet]="CheckBox"
            [ngTemplateOutletContext]="{ check: checkCreatedEditedBy() }"
          ></ng-container>
          <label for="ingredient1" class="ml-2"> Seller </label>
        </div>

        <div class="flex align-items-center">
          <ng-container
            [ngTemplateOutlet]="CheckBox"
            [ngTemplateOutletContext]="{ check: !checkCreatedEditedBy() }"
          ></ng-container>
          <label for="ingredient2" class="ml-2"> Admin </label>
        </div>
      </div>
    </div>
  </div>

  <div class="flex">
    <!-- LEFT SECTION 1-->
    <div class="w-1/2 mr-4">
      <app-product-upload-images
        [(attachments)]="productForm.attachments"
        [attachmentsDisplay]="attachments"
        (attachmentsDisplayChange)="onAttachmentsChange($event)"
        [(avatarIndex)]="productForm.avatarIndex"
        [isViewMode]="isViewMode"
      ></app-product-upload-images>
    </div>
    <!-- RIGHT SECTION 1-->
    <div class="flex-1 flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <label class="text-sm text-gray-700 font-medium" htmlFor="name">
          {{ managementPrefix + "product-name" | translate | sentenceCase }}
          <span class="text-orange-dark-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>
        <input
          #productName="ngModel"
          [(ngModel)]="productForm.name"
          [placeholder]="managementPrefix + 'enter-product-name' | translate | sentenceCase"
          class="h-10"
          name="product_name"
          pInputText
          required
          [maxlength]="120"
          [minlength]="10"
          [readOnly]="isViewMode"
        />
        <div class="flex justify-between">
          <span>
            <small *ngIf="productName.errors?.['minlength']" class="flex text-error-500 text-xs">{{
              managementPrefix + "validate.name-min-length" | translate
            }}</small>
          </span>
          <small
            class="flex text-gray-500 text-xs justify-end {{
              productForm.name.length == 120 ? 'error-highlight-animation' : ''
            }}"
            id="name-help"
          >
            {{ productForm.name.length }}/120
            {{ "common.characters" | translate }}
          </small>
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium" htmlFor="category">
          {{ "common.category" | translate | sentenceCase }}
          <span class="text-orange-dark-500 {{ isViewMode ? 'hidden' : '' }}">*</span>
        </label>

        <!-- CATEGORY -->
        <p-treeSelect
          *ngIf="!isViewMode"
          [required]="true"
          [(ngModel)]="productForm.category"
          (onNodeSelect)="onSelectCategory($event)"
          [metaKeySelection]="false"
          name="category"
          [options]="categories"
          [placeholder]="managementPrefix + 'text-description.select-a-category' | translate | sentenceCase"
          [style]="{ width: '100%' }"
          containerStyleClass="w-full"
          [required]="true"
          [disabled]="isViewMode"
        >
          <ng-template let-expanded pTemplate="itemtogglericon">
            <span
              class="
              {{ !expanded ? SoctripIcons.CHEVRON_RIGHT : SoctripIcons.CHEVRON_DOWN }}"
            ></span
          ></ng-template>
        </p-treeSelect>

        <input *ngIf="isViewMode" readonly type="text" pInputText [value]="productForm.category?.name_final || ''" />
      </div>
      <!-- Hashtags -->
      <div class="flex flex-col gap-2">
        <label class="text-sm font-medium" htmlFor="category">
          {{ "common.hashtags" | translate | sentenceCase }}
        </label>
        <div class="card p-fluid" [ariaReadOnly]="isViewMode">
          <p-chips
            (onAdd)="onAddChip($event)"
            [(ngModel)]="productForm.hashtags"
            [inputStyle]="{ 'box-shadow': 'unset' }"
            [max]="20"
            [ngModelOptions]="{ standalone: true }"
            [placeholder]="('common.hint' | translate | sentenceCase) + ': a, b c'"
            separator=","
            [disabled]="isViewMode"
            [addOnBlur]="true"
          >
          </p-chips>
        </div>
        <small class="flex text-gray-500 text-xs justify-end">
          {{ productForm.hashtags.length }}/20
          {{ "common.hashtags" | translate | sentenceCase }}
        </small>
      </div>
    </div>
  </div>
</div>

<ng-template #CheckBox let-check="check">
  <div
    class="flex justify-center items-center h-5 w-5 rounded-full p-[2px] border"
    [ngClass]="{ 'border-blue-500': check, 'border-gray-300': !check }"
  >
    <div class="h-[15px] w-[15px] rounded-full" [ngClass]="{ 'bg-blue-500': check }"></div>
  </div>
</ng-template>
